<template>
  <div class="service">
    <!-- pc端 -->
    <div v-if="!_isMobile()">
      <div class="header_center">
        <div class="center1"></div>
        <center-slot>
          <template #txt1> SERVICE PROCESS </template>
          <template #txt2> 服务流程 </template>
          <template #txt3>
            售前售后全流程服务，即时响应，给高校教师和学生带来更好的体验
          </template>
        </center-slot>
        <div class="contain">
          <div class="center2">
            <img class="img1" src="@/assets/clothes/clothes_1.png" />
            <div class="lis">
              <ul>
                <li>
                  <div class="left">
                    <img src="@/assets/clothes/clothes_1.1.png" />
                  </div>
                  <div class="right">
                    <p>了解需求</p>
                    <p>根据高校需求，建立适配合作关系。</p>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <img src="@/assets/clothes/clothes_1.2.png" />
                  </div>
                  <div class="right">
                    <p>专人对接</p>
                    <p>
                      向知与高校达成合作后，设置专属助教老师与高校老师进行对接，将协力完成学生选课、账号开通及后续陪伴式学习督导工作。
                    </p>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <img src="@/assets/clothes/clothes_1.3.png" />
                  </div>
                  <div class="right">
                    <p>配套运营</p>
                    <p>
                      在服务应用过程中，学校老师或学生遇到任何问题，可第一时间联系助教老师答疑解惑。
                      <br />
                      根据学校具体要求，制定个性化校园活动方案，拉近名师与学生之间的距离，让校园生活多元更多彩。
                    </p>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <img src="@/assets/clothes/clothes_1.4.png" />
                  </div>
                  <div class="right">
                    <p>学情跟进</p>
                    <p>
                      完善的在线教务系统功能，高校老师可在线实时查看学生学习及练习情况。
                      <br />
                      向知服务团队亦将根据高校学生的学习情况，以月、学期为单位输出相应学情报告及跨校区域报告，定期与老师进行沟通，保障学生完成学习闭环。
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <center-slot>
          <template #txt1> PLATFORM SERVICES</template>
          <template #txt2> 学习平台服务 </template>
          <template #txt3>
            一站式教学教务平台，根据授课场景和教学需求打造完善的产品功能
          </template>
        </center-slot>
        <div class="center3" v-if="this.imgeShow">
          <img src="@/assets/clothes/clothes_2.png" />
        </div>
        <div class="center3" v-else>
          <img src="@/assets/clothes/clothes_2.2.png" />
        </div>
        <div class="radio1 contain" v-if="this.imgeShow">
          <span @click="imgeShow1"></span>
          <span @click="imgeShow2"></span>
        </div>
        <div class="radio2 contain" v-else>
          <span @click="imgeShow1"></span>
          <span @click="imgeShow2"></span>
        </div>
        <center-slot>
          <template #txt1> GUARANTEE</template>
          <template #txt2> 安全有保障 </template>
          <template #txt3>
            备案认证齐全，专业技术团队持续运维，确保平台安全稳定
          </template>
        </center-slot>
        <div class="contain">
          <div class="center4">
            <ul>
              <li>
                <img src="@/assets/clothes/clothes_3.1.png" />
                <div class="about2">专门的安全团队</div>
                <p class="about3">
                  向知有专门的安全团队，处理来自互联网的安全威胁，管理和审查数据的访问操作。
                </p>
              </li>
              <li>
                <img src="@/assets/clothes/clothes_3.2.png" />
                <div class="about2">公安部信息安全三级认证</div>
                <p class="about3">
                  向知的相关平台服务，已获得国家非金融机构的安全认证—公安部信息系统安全等级保护三级认证。
                </p>
              </li>
              <li>
                <img src="@/assets/clothes/clothes_3.3.png" />
                <div class="about2">关键隐私数据加密</div>
                <p class="about3">
                  关键隐私数据加密：如手机号、密码等涉及个人隐私数据，基于底层服务的数据加密算法保护。
                </p>
              </li>
              <li>
                <img src="@/assets/clothes/clothes_3.4.png" />
                <div class="about2">教育移动互联网应用程序备案</div>
                <p class="about3">
                  向知APP已经通过教育移动互联网应用程序备案审核。
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="img2" v-if="!_isMobile()">
        <img src="@/assets/code3.png" />
      </div>
    </div>
    <MyFooter class="footer" v-if="!_isMobile()" />
    <!-- 移动端 -->
    <div class="moblie-box" v-if="_isMobile()">
      <img src="@/assets/moblie/bg_06.png" class="top-img" />
      <div class="list">
        <p>服务流程</p>
        <div class="list-box">
          <img src="@/assets/clothes/clothes_1.1.png" />
          <div class="list-r">
            <span class="title">了解需求</span>
            <span class="text">根据高校需求，建立适配合作关系。</span>
          </div>
        </div>
        <div class="list-box">
          <img src="@/assets/clothes/clothes_1.2.png" />
          <div class="list-r">
            <span class="title">专人对接</span>
            <span class="text">
              向知与高校达成合作后，设置专属助教老师与高校老师进行对接，将协力完成学生选课、账号开通及后续陪伴式学习督导工作。
            </span>
          </div>
        </div>
        <div class="list-box">
          <img src="@/assets/clothes/clothes_1.3.png" />
          <div class="list-r">
            <span class="title">配套运营</span>
            <span class="text">
              在服务应用过程中，学校老师或学生遇到任何问题，可第一时间联系助教老师答疑解惑。根据学校具体要求，制定个性化校园活动方案，拉近名师与学生之间的距离，让校园生活多元更多彩。
            </span>
          </div>
        </div>
        <div class="list-box">
          <img src="@/assets/clothes/clothes_1.4.png" />
          <div class="list-r">
            <span class="title">学情跟进</span>
            <span class="text">
              完善的在线教务系统功能，高校老师可在线实时查看学生学习及练习情况。向知服务团队亦将根据高校学生的学习情况，以月、学期为单位输出相应学情报告及跨校区域报告，定期与老师进行沟通，保障学生完成学习闭环。
            </span>
          </div>
        </div>
      </div>
      <div class="img-box">
        <p>学习平台服务</p>
        <div class="center3" v-if="this.imgeShow" @touchend="imgeShow2">
          <img src="@/assets/clothes/clothes_2.png" />
        </div>
        <div class="center3" v-else @touchend="imgeShow1">
          <img src="@/assets/clothes/clothes_2.2.png" />
        </div>
        <div class="radio1 contain" v-if="this.imgeShow">
          <span @click="imgeShow1"></span>
          <span @click="imgeShow2"></span>
        </div>
        <div class="radio2 contain" v-else>
          <span @click="imgeShow1"></span>
          <span @click="imgeShow2"></span>
        </div>
      </div>
      <div class="safe-box">
        <p>安全有保障</p>
        <div>
          <img src="@/assets/clothes/clothes_3.1.png" />
          <span>专门的安全团队</span>
        </div>
        <div>
          <img src="@/assets/clothes/clothes_3.2.png" />
          <span>公安部信息安全三级认证</span>
        </div>
        <div>
          <img src="@/assets/clothes/clothes_3.3.png" />
          <span>关键隐私数据加密</span>
        </div>
        <div>
          <img src="@/assets/clothes/clothes_3.4.png" />
          <span>教育移动互联网应用程序备案</span>
        </div>
      </div>
    </div>
    <MyMoblieFooter class="footer" v-if="_isMobile()" />
  </div>
</template>

<script>
export default {
  name: 'Service',
  data () {
    return {
      show: true,
      imgeShow: true
    }
  },
  methods: {
    showClick () {
      this.show = !this.show
    },
    imgeShow1 () {
      this.imgeShow = true
    },
    imgeShow2 () {
      this.imgeShow = false
    }
  }
}
</script>

<style scoped lang="less">
.moblie-box {
  background: #fff;
  .top-img {
    width: 100%;
    height: auto;
  }
  p {
    font-size: 0.4rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    text-align: center;
    margin-bottom: 0.4rem;
  }
  .list {
    display: flex;
    flex-flow: column;
    padding: 0.4rem;
    .list-box {
      display: flex;
      padding-bottom: 0.4rem;
      img {
        width: 0.52rem;
        height: 0.48rem;
        margin-right: 0.2rem;
      }
      .list-r {
        display: flex;
        flex: 1;
        flex-flow: column;
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 0.38rem;
        .title {
          font-size: 0.36rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .text {
          font-size: 0.28rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #929292;
        }
      }
    }
    div:last-child .list-r {
      border: none;
      padding: 0;
    }
  }
  .img-box {
    margin: 0 0.36rem 0.52rem;
    .center3 {
      display: flex;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        background: #ffffff;
        border-radius: 20px;
        flex-shrink: 1;
      }
    }
    .radio1 {
      margin-top: 0.28rem;
      span:first-child {
        z-index: 48;
        display: inline-block;
        width: 0.5rem;
        height: 0.1rem;
        background: #1e4b4b;
        border-radius: 8px 0px 0px 8px;
      }
      span:last-child {
        display: inline-block;
        width: 0.5rem;
        height: 0.1rem;
        background: #f0f0f0;
        border-radius: 0px 8px 8px 0px;
      }
    }
    .radio2 {
      margin-top: 0.28rem;
      span:first-child {
        z-index: 48;
        display: inline-block;
        width: 0.5rem;
        height: 0.1rem;
        background: #f0f0f0;
        border-radius: 8px 0px 0px 8px;
      }
      span:last-child {
        display: inline-block;
        width: 0.5rem;
        height: 0.1rem;
        background: #1e4b4b;
        border-radius: 0px 8px 8px 0px;
      }
    }
  }
  .safe-box {
    display: flex;
    flex-flow: column;
    padding: 0 0.62rem;
    div {
      display: flex;
      align-items: center;
      padding: 0.1rem;
    }
    img {
      width: 0.88rem;
      height: 0.88rem;
    }
    span {
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      padding-left: 0.2rem;
    }
  }
}
.header_center {
  height: 100%;
  margin-bottom: 121px;
  .center1 {
    width: 100%;
    height: 633px;
    background: url("~@/assets/clothes/clothes_bg.jpg") no-repeat center / cover;
    margin-bottom: 80px;
    margin-top: -81px;
  }

  .center2 {
    position: relative;
    width: 1200px;
    height: 444px;
    margin-bottom: 112px;
    margin-top: 122px;
    .img1 {
      display: inline-block;
      width: 389px;
      height: 444px;
    }
    .lis {
      display: inline-block;
      position: absolute;
      top: 0;
      width: 740px;
      height: 444px;
      margin-left: 65px;
      ul {
        li {
          list-style: none;
          border-bottom: 1px solid #dddddd;
          padding-top: 23px;
          padding-bottom: 22px;
          &:last-child {
            border-bottom: none;
          }
          &:first-child {
            padding-top: 0;
          }
          .left {
            float: left;
            width: 41px;
            height: 38px;
            margin-left: 11px;
            img {
              width: 46px;
              height: 46px;
              background-size: contain;
            }
          }
          .right {
            margin-left: 67px;
            p:nth-child(1) {
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 28px;
            }
            p:nth-child(2) {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              color: #929292;
              line-height: 20px;
              margin-top: 7px;
            }
          }
        }
      }
    }
  }

  .center3 {
    display: flex;
    justify-content: center;
    margin-bottom: 21px;
    img {
      width: 1200px;
      height: 612px;
      background: #ffffff;
      border-radius: 20px;
      margin-top: 67px;
    }
  }
  .radio1 {
    margin-bottom: 75px;
    span:first-child {
      display: inline-block;
      width: 41px;
      height: 8px;
      background: #1e4b4b;
      border-radius: 8px 0px 0px 8px;
    }
    span:last-child {
      display: inline-block;
      width: 41px;
      height: 8px;
      background: #f0f0f0;
      border-radius: 0px 8px 8px 0px;
    }
  }
  .radio2 {
    margin-bottom: 75px;
    span:first-child {
      display: inline-block;
      width: 41px;
      height: 8px;
      background: #f0f0f0;
      border-radius: 8px 0px 0px 8px;
    }
    span:last-child {
      display: inline-block;
      width: 41px;
      height: 8px;
      background: #1e4b4b;
      border-radius: 0px 8px 8px 0px;
    }
  }
  .center4 {
    height: 335px;
    margin-top: 89px;
    ul {
      display: flex;
      li {
        width: 300px;
        height: 220px;
        background: #ffffff;
        border-radius: 8px;
        list-style: none;
        margin-top: 54px;
        box-shadow: 0px 2px 38px 0px #f0f0f0;
        &:hover {
          transform: scale(1.5);
          z-index: 10;
        }
        &:nth-child(2n) {
          margin-left: 6px;
          margin-right: 6px;
        }
        &:nth-child(4) {
          margin-right: 0;
        }
        img {
          width: 67px;
          height: 67px;
          background-size: contain;
          margin-left: 120px;
          margin-top: 27px;
        }
        .about2 {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
          text-align: center;
          margin-top: 16px;
        }
        .about3 {
          height: 42px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 18px;
          margin: 17px 25px 40px;
        }
      }
    }
  }
}
.img2 {
  z-index: 100;
  width: 184px;
  height: 184px;
  position: fixed;
  bottom: 0;
  right: 0;
  img {
    width: 150px;
    height: 150px;
    background-size: contain;
  }
}
</style>
